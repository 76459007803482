import React from 'react';
import classNames from 'classnames';

import styles from './BasicInfoSection.module.scss';

export interface BasicInfoSectionProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
}

export const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({ title, className, children, ...props }) => (
  <div className={classNames(styles.BasicInfoSection, className)} {...props}>
    {title && <h2 className={styles.Title}>{title}</h2>}
    <div className={styles.Content}>{children}</div>
  </div>
);
