import React from 'react';
import classNames from 'classnames';

import { LanguageSelectorProps } from './exportedTypes';
import { LanguageSelector_LanguageItem } from './LanguageItem';

import styles from './LanguageSelector.module.scss';

export const LanguageSelector = ({
  className,
  children,
  ...props
}: React.PropsWithChildren<LanguageSelectorProps>): React.ReactElement => (
  <div className={classNames(styles.LanguageSelector, className)} {...props}>
    {children}
  </div>
);

LanguageSelector.Item = LanguageSelector_LanguageItem;
