import React from 'react';
import classNames from 'classnames';

import { ImageComponentProps } from '../types';

import linkedInImage from '../../../images/linkedin-white.svg';
import styles from '../SocialMedia.module.scss';

export const LinkedIn: React.FC<ImageComponentProps> = ({ className, alt, ...props }) => (
  <img className={classNames(styles.LinkedIn, className)} alt={alt || ''} src={linkedInImage} {...props} />
);
