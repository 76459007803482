import { useEffect } from 'react';

import { LanguageName, useLanguage } from '../language';

export const useSystemLanguage = (): void => {
  const [, setLanguage] = useLanguage();

  useEffect(() => {
    if (navigator.language.startsWith('en')) {
      setLanguage(LanguageName.English);
    } else if (navigator.language.startsWith('pl')) {
      setLanguage(LanguageName.Polish);
    }
  }, []);
};
