import React from 'react';
import classNames from 'classnames';

import { LanguageItemProps } from './exportedTypes';
import { getImagePath } from './getImagePath';

import styles from './LanguageSelector.module.scss';

export const LanguageSelector_LanguageItem = ({
  className,
  language,
  ...props
}: React.PropsWithChildren<LanguageItemProps>): React.ReactElement => (
  <button className={classNames(styles.LanguageItem, className)} tabIndex={0} {...props}>
    <img className={styles.Image} alt={language} src={getImagePath(language)} />
  </button>
);
