import React from 'react';

export interface LanguageSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactElement<LanguageItemProps> | React.ReactElement<LanguageItemProps>[];
}

export interface LanguageItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  language: Language;
}

export enum Language {
  Polish = 'Polish',
  English = 'English',
}
