import React from 'react';
import classNames from 'classnames';

import { ListProps } from './exportedTypes';
import { ListItem } from './ListItem';

import styles from './List.module.scss';

export const List = ({ className, children, ...props }: React.PropsWithChildren<ListProps>): React.ReactElement => (
  <ul className={classNames(styles.List, className)} {...props}>
    {children}
  </ul>
);

List.Item = ListItem;
