import React from 'react';
import classNames from 'classnames';

import { ImageComponentProps } from '../types';

import facebookImage from '../../../images/facebook-white.svg';
import styles from '../SocialMedia.module.scss';

export const Facebook: React.FC<ImageComponentProps> = ({ className, alt, ...props }) => (
  <img className={classNames(styles.Facebook, className)} alt={alt || ''} src={facebookImage} {...props} />
);
