import React from 'react';
import classNames from 'classnames';

import styles from './SkillWithPercentageValue.module.scss';

export interface SkillWithPercentageValueProps extends React.HTMLAttributes<HTMLLabelElement> {
  skill: string;
  percentage: number;
}

export const SkillWithPercentageValue: React.FC<SkillWithPercentageValueProps> = ({
  skill,
  percentage,
  className,
  ...props
}) => {
  const percentageValue = Math.min(Math.max(percentage, 0), 100);

  return (
    <label className={classNames(styles.SkillWithPercentageValue, className)} {...props}>
      <h4>{skill}</h4>
      <div className={styles.ProgressContainer}>
        <div
          className={styles.Progress}
          role="progressbar"
          style={{ width: percentageValue + '%' }}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={percentageValue}
        />
      </div>
    </label>
  );
};
