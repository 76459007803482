import React from 'react';

export interface SocialMediaProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<SocialMediaItemProps> | React.ReactElement<SocialMediaItemProps>[];
}

export interface SocialMediaItemProps extends React.HTMLProps<HTMLAnchorElement> {
  socialType: SocialType;
}

export interface SocialMediaTypeProps extends React.HTMLProps<HTMLSpanElement> {
  socialType: SocialType;
}

export enum SocialType {
  Facebook = 'Facebook',
  LinkedIn = 'LinkedIn',
  GoldenLine = 'GoldenLine',
}
