import React from 'react';

import { Facebook, GoldenLine, LinkedIn } from './imageComponents';
import { SocialType } from './exportedTypes';
import { ImageComponentProps } from './types';

export const getSocialMediaImageComponentFromType = (socialType: SocialType): React.FC<ImageComponentProps> => {
  switch (socialType) {
    case SocialType.Facebook:
      return Facebook;
    case SocialType.LinkedIn:
      return LinkedIn;
    case SocialType.GoldenLine:
      return GoldenLine;
  }

  return () => null;
};
