import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { store, RootState } from '../app/store';
import { LanguageGetter, LanguageModel, LanguageName } from './types';
import { setLanguage as setNewLanguage } from './slice';

export const setLanguage = (language: LanguageName): void => {
  store.dispatch(setNewLanguage(language));
};

export const useLanguage = (): [LanguageGetter, (language: LanguageName) => void] => {
  const languageModelValues = useSelector(({ languageStore }: RootState) => languageStore.language);
  const languageRef = useRef(languageModelValues);

  languageRef.current = languageModelValues;

  const language = useRef<LanguageGetter>(
    (callback: (l: LanguageModel) => any): string => '' + (callback(languageRef.current) || ''),
  );

  return [language.current, setLanguage];
};
