import React from 'react';
import classNames from 'classnames';

import { ExperienceDescriptionProps } from './exportedTypes';

import styles from './ExperienceItem.module.scss';

export const ExperienceDescription: React.FC<ExperienceDescriptionProps> = ({ className, children, ...props }) => (
  <h4 className={classNames(styles.Description, className)} {...props}>
    {children}
  </h4>
);
