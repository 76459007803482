export enum LanguageName {
  English = 'english',
  Polish = 'polish',
}

export interface LanguageState {
  language: LanguageModel;
}

export type LanguageGetter = (callback: (l: LanguageModel) => any) => string;

interface Education {
  degree: string;
  school: string;
  department: string;
  fieldOfStudy: string;
}

interface Experience {
  companyName: string;
  workPosition: string;
  jobDescription: string;
  jobDescriptionBolded?: string;
}

export interface LanguageModel {
  languageInfo: {
    name: LanguageName;
  };
  sections: {
    socialMedia: string;
    abilities: string;
    languages: string;
    experience: string;
    education: string;
    achievements: string;
    additionSkills: string;
    hobby: string;
  };
  personalData: {
    name: string;
    surname: string;
    position: string;
  };
  languages: {
    polish: string;
    english: string;
  };
  experience: {
    barak: Experience;
    astor: Experience;
    kimla: Experience;
    tieto: Experience;
    nokiaLte: Experience;
    publishThis: Experience;
    nokiaReact: Experience;
    sii: Experience;
    sternum: Experience;
  };
  education: {
    engineer: Education;
    master: Education;
  };
  achievements: {
    sep: string;
    cladCertificateLabView: string;
    microboticsSummerWorkshop: string;
    laureatePhysicsAndEcology: string;
    youngScientists: string;
  };
  additionSkills: {
    projectManagementTechniques: string;
    drivingLicense: string;
  };
  hobby: {
    creatingWebsites: string;
    homeManagementSystem: string;
    readingSelfDevBooks: string;
    dancing: string;
    photography: string;
  };
  common: {
    toNow: string;
    educationDepartment: string;
    educationFieldOfStudy: string;
  };
  cvClause: string;
}
