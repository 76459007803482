import React from 'react';
import classNames from 'classnames';

import { ExperienceWorkPositionProps } from './exportedTypes';

import styles from './ExperienceItem.module.scss';

export const ExperienceWorkPosition: React.FC<ExperienceWorkPositionProps> = ({ className, children, ...props }) => (
  <h3 className={classNames(styles.WorkPosition, className)} {...props}>
    {children}
  </h3>
);
