import React from 'react';
import classNames from 'classnames';

import { ExperienceItemProps } from './exportedTypes';
import { ExperiencePlace } from './ExperiencePlace';
import { ExperienceTimeRange } from './ExperienceTimeRange';
import { ExperienceWorkPosition } from './ExperienceWorkPosition';
import { ExperienceDescription } from './ExperienceDescription';
import { ExperienceDescriptionAddon } from './ExperienceDescriptionAddon';
import { Decorator } from './Decorator';

import styles from './ExperienceItem.module.scss';

export const ExperienceItem = ({
  className,
  children,
  ...props
}: React.PropsWithChildren<ExperienceItemProps>): React.ReactElement => (
  <div className={classNames(styles.ExperienceItem, className)} {...props}>
    {children}
    <Decorator className={styles.Decorator} />
  </div>
);

ExperienceItem.TimeRange = ExperienceTimeRange;
ExperienceItem.Place = ExperiencePlace;
ExperienceItem.WorkPosition = ExperienceWorkPosition;
ExperienceItem.Description = ExperienceDescription;
ExperienceItem.DescriptionAddon = ExperienceDescriptionAddon;
