import React from 'react';
import classNames from 'classnames';

import styles from './ExperienceItem.module.scss';

export const Decorator: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, children, ...props }) => (
  <div className={classNames(styles.Decorator, className)} {...props}>
    {children}
  </div>
);
