import React from 'react';
import classNames from 'classnames';

import { SocialMediaItemProps } from './exportedTypes';

import styles from './SocialMedia.module.scss';
import { getSocialMediaImageComponentFromType } from './getSocialMediaImageComponentFromType';

export const SocialMediaItem = ({
  className,
  children,
  href,
  socialType,
  ...props
}: React.PropsWithChildren<SocialMediaItemProps>): React.ReactElement => {
  const ImageComponent = getSocialMediaImageComponentFromType(socialType);

  return (
    <a
      className={classNames(styles.SocialItem, className)}
      href={href}
      target="_blank"
      rel="noreferrer"
      tabIndex={0}
      {...props}
    >
      <ImageComponent />
      <div className={styles.SocialItemLabel}>{children}</div>
    </a>
  );
};
