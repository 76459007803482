import React from 'react';
import classNames from 'classnames';

import { ExperienceDescriptionAddonProps } from './exportedTypes';

import styles from './ExperienceItem.module.scss';

export const ExperienceDescriptionAddon: React.FC<ExperienceDescriptionAddonProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames(styles.DescriptionAddon, className)} {...props}>
    {children}
  </div>
);
