import React from 'react';

import { Document } from './containers/Document';
import { BasicInformation } from './features/BasicInformation';
import { MainContent } from './features/MainContent';
import { useSystemLanguage } from './app/useSystemLanguage';

const App: React.FC = () => {
  useSystemLanguage();

  return (
    <Document>
      <BasicInformation />
      <MainContent />
    </Document>
  );
};

export default App;
