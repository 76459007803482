import React from 'react';

export interface EducationItemProps extends React.HTMLProps<HTMLDivElement> {
  type: EducationType;
}

export enum EducationType {
  Department = 'department',
  FieldOfStudy = 'fieldOfStudy',
}
