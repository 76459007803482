import React from 'react';
import classNames from 'classnames';

import styles from './MainContentSection.module.scss';

export interface MainContentSectionProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
}

export const MainContentSection: React.FC<MainContentSectionProps> = ({ className, title, children, ...props }) => (
  <div className={classNames(styles.MainContentSection, className)} {...props}>
    <h2 className={styles.Title}>{title}</h2>
    <article>{children}</article>
  </div>
);
