import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import defaultLanguage from './lang/english';
import { AppThunk } from '../app/store';
import { LanguageModel, LanguageName, LanguageState } from './types';

const initialState: LanguageState = {
  language: defaultLanguage,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageModel>) => {
      state.language = action.payload;
    },
  },
});

const { setLanguage: setLanguageAction } = languageSlice.actions;

export default languageSlice.reducer;

export const setLanguage =
  (language: LanguageName): AppThunk =>
  async (dispatch) => {
    try {
      const { default: newLanguage } = await import(`./lang/${language}`);
      dispatch(setLanguageAction(newLanguage));
    } catch {
      console.error(`There is no language ${language}`);
    }
  };
