import React from 'react';

import { BasicInformationContainer } from '../../containers/BasicInformationContainer';
import { BasicInfoSection } from '../../containers/BasicInfoSection';
import { LanguageName, useLanguage } from '../../language';
import { getBasicInfoData } from './getBasicInfoData';
import { TitleNameSurname, TitleWorkPlace } from '../../components/Titles';
import { SkillWithPercentageValue } from '../../components/SkillWithPercentageValue';
import { SocialMedia, SocialMediaTypes } from '../../components/SocialMedia';
import { ImageCv } from '../../components/ImageCv';

import cvImage from '../../images/MichalG.jpg';
import { LanguageSelector, LanguageSelectorTypes } from '../../components/LanguageSelector';

export const BasicInformation: React.FC = () => {
  const [language, setLanguage] = useLanguage();

  const basicInfoData = getBasicInfoData(language);

  return (
    <BasicInformationContainer>
      <BasicInfoSection>
        <LanguageSelector>
          <LanguageSelector.Item
            language={LanguageSelectorTypes.Language.Polish}
            onClick={() => setLanguage(LanguageName.Polish)}
          />
          <LanguageSelector.Item
            language={LanguageSelectorTypes.Language.English}
            onClick={() => setLanguage(LanguageName.English)}
          />
        </LanguageSelector>
        <TitleNameSurname>
          {basicInfoData.personalData.name}
          <br />
          {basicInfoData.personalData.surname}
        </TitleNameSurname>
        <TitleWorkPlace>{basicInfoData.personalData.position}</TitleWorkPlace>
        <ImageCv imagePath={cvImage} />
      </BasicInfoSection>
      <BasicInfoSection title={language((l) => l.sections.socialMedia)}>
        <SocialMedia>
          <SocialMedia.Item socialType={SocialMediaTypes.SocialType.LinkedIn} href={basicInfoData.socialMedia.linkedIn}>
            LinkedIn
          </SocialMedia.Item>
          <SocialMedia.Item socialType={SocialMediaTypes.SocialType.Facebook} href={basicInfoData.socialMedia.facebook}>
            Facebook
          </SocialMedia.Item>
          <SocialMedia.Item
            socialType={SocialMediaTypes.SocialType.GoldenLine}
            href={basicInfoData.socialMedia.goldenLine}
          >
            Golden Line
          </SocialMedia.Item>
        </SocialMedia>
      </BasicInfoSection>
      <BasicInfoSection title={language((l) => l.sections.abilities)}>
        {basicInfoData.abilities.map((ability) => (
          <SkillWithPercentageValue key={ability.name} skill={ability.name} percentage={ability.percentValue} />
        ))}
      </BasicInfoSection>
      <BasicInfoSection title={language((l) => l.sections.languages)}>
        {basicInfoData.languages.map((lang) => (
          <SkillWithPercentageValue key={lang.name} skill={lang.name} percentage={lang.percentValue} />
        ))}
      </BasicInfoSection>
    </BasicInformationContainer>
  );
};
