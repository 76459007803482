import { EducationType } from './exportedTypes';
import { LanguageGetter } from '../../language';

export const getEducationTitle = (type: EducationType, language: LanguageGetter): string => {
  const titlePerEducationType: { [T in EducationType]: string } = {
    [EducationType.Department]: language((l) => l.common.educationDepartment),
    [EducationType.FieldOfStudy]: language((l) => l.common.educationFieldOfStudy),
  };

  return titlePerEducationType[type];
};
