import React from 'react';

import { getBasicInfoData } from './getMainContentData';
import { useLanguage } from '../../language';
import { MainContentContainer } from '../../containers/MainContentContainer';
import { MainContentSection } from '../../containers/MainContentSection';
import { ExperienceItem } from '../../components/ExperienceItem';
import { EducationItem, EducationItemTypes } from '../../components/EducationItem';
import { List } from '../../components/List';

import styles from './MainContent.module.scss';

export const MainContent: React.FC = () => {
  const [language] = useLanguage();
  const mainContentData = getBasicInfoData(language);

  return (
    <MainContentContainer className={styles.MainContent}>
      <MainContentSection title={language((l) => l.sections.experience)}>
        <div className={styles.ExperienceContainer}>
          {mainContentData.experience.map((experience, index) => (
            <ExperienceItem key={index}>
              <ExperienceItem.TimeRange from={experience.fromDate} to={experience.toDate} />
              <ExperienceItem.WorkPosition>{experience.workPosition}</ExperienceItem.WorkPosition>
              <ExperienceItem.Place>{experience.companyName}</ExperienceItem.Place>
              <ExperienceItem.Description>
                {experience.jobDescriptionBolded && (
                  <ExperienceItem.DescriptionAddon>{experience.jobDescriptionBolded}</ExperienceItem.DescriptionAddon>
                )}
                {experience.jobDescription}
              </ExperienceItem.Description>
            </ExperienceItem>
          ))}
        </div>
      </MainContentSection>
      <MainContentSection title={language((l) => l.sections.education)}>
        <div className={styles.ExperienceContainer}>
          {mainContentData.education.map((education, index) => (
            <ExperienceItem key={index}>
              <ExperienceItem.TimeRange from={education.fromDate} to={education.toDate} />
              <ExperienceItem.WorkPosition>{education.degree}</ExperienceItem.WorkPosition>
              <ExperienceItem.Place>{education.school}</ExperienceItem.Place>
              <ExperienceItem.Description>
                <EducationItem type={EducationItemTypes.EducationType.Department}>{education.department}</EducationItem>
                <EducationItem type={EducationItemTypes.EducationType.FieldOfStudy}>
                  {education.fieldOfStudy}
                </EducationItem>
                <div></div>
              </ExperienceItem.Description>
            </ExperienceItem>
          ))}
        </div>
      </MainContentSection>
      <MainContentSection title={language((l) => l.sections.achievements)}>
        <div className={styles.ExperienceContainer}>
          <List>
            {mainContentData.achievements.map((achievement, index) => (
              <List.Item key={index}>{achievement}</List.Item>
            ))}
          </List>
        </div>
      </MainContentSection>
      <MainContentSection title={language((l) => l.sections.additionSkills)}>
        <div className={styles.ExperienceContainer}>
          <List>
            {mainContentData.additionSkills.map((additionalSkill, index) => (
              <List.Item key={index}>{additionalSkill}</List.Item>
            ))}
          </List>
        </div>
      </MainContentSection>
      <MainContentSection title={language((l) => l.sections.hobby)}>
        <div className={styles.ExperienceContainer}>
          <List>
            {mainContentData.hobby.map((hobby, index) => (
              <List.Item key={index}>{hobby}</List.Item>
            ))}
          </List>
        </div>
      </MainContentSection>
    </MainContentContainer>
  );
};
