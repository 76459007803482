import { LanguageGetter } from '../../language';
import { BasicInfoData } from './types';

export const getBasicInfoData = (language: LanguageGetter): BasicInfoData => ({
  personalData: {
    name: language((l) => l.personalData.name),
    surname: language((l) => l.personalData.surname),
    position: language((l) => l.personalData.position),
  },
  socialMedia: {
    linkedIn: 'https://pl.linkedin.com/in/michal-grzezulkowski',
    facebook: 'https://www.facebook.com/michal.grzezulkowski',
    goldenLine: 'https://www.goldenline.pl/michal-grzezulkowski/',
  },
  languages: [
    { name: language((l) => l.languages.polish), percentValue: 99 },
    { name: language((l) => l.languages.english), percentValue: 85 },
  ],
  abilities: [
    { name: 'React (Typescript)', percentValue: 92 },
    { name: 'Redux', percentValue: 80 },
    { name: 'Git / Gerrit', percentValue: 90 },
    { name: 'NodeJS', percentValue: 78 },
    { name: 'Python', percentValue: 70 },
    { name: 'HTML5 / CSS / SCSS / Styled-components', percentValue: 90 },
    { name: 'Docker', percentValue: 60 },
    { name: 'C / C++', percentValue: 50 },
  ],
});
