import React from 'react';
import classNames from 'classnames';

import { EducationItemProps } from './exportedTypes';
import { getEducationTitle } from './getEducationTitle';

import styles from './EducationItem.module.scss';
import { useLanguage } from '../../language';

export const EducationItem = ({
  className,
  type,
  children,
  ...props
}: React.PropsWithChildren<EducationItemProps>): React.ReactElement => {
  const [language] = useLanguage();
  const educationTitle = getEducationTitle(type, language);

  return (
    <div className={classNames(styles.EducationItem, className)} {...props}>
      <span>{educationTitle}: </span>
      {children}
    </div>
  );
};
