import React from 'react';
import classNames from 'classnames';

import { ListItemProps } from './exportedTypes';

import styles from './List.module.scss';

export const ListItem = ({
  className,
  children,
  ...props
}: React.PropsWithChildren<ListItemProps>): React.ReactElement => (
  <li className={classNames(styles.ListItem, className)} {...props}>
    {children}
  </li>
);
