import { LanguageSelectorTypes } from '.';

import polishFlag from '../../images/FlagOfPoland.svg';
import englishUKFlag from '../../images/FlagOfUnitedKingdom.svg';
import englishUSAFlag from '../../images/FlagOfUSA.svg';

export const getImagePath = (language: LanguageSelectorTypes.Language): string => {
  const languageCode = navigator.language?.toLocaleLowerCase();

  switch (language) {
    case LanguageSelectorTypes.Language.Polish:
      return polishFlag;
    case LanguageSelectorTypes.Language.English:
      if (languageCode === 'en-gb') {
        return englishUKFlag;
      } else {
        return englishUSAFlag;
      }
  }

  return '';
};
