import React from 'react';
import classNames from 'classnames';

import styles from './ImageCv.module.scss';

export interface ImageCvProps extends React.HTMLAttributes<HTMLImageElement> {
  imagePath: string;
}

export const ImageCv: React.FC<ImageCvProps> = ({ className, imagePath }) => (
  <img className={classNames(styles.ImageCv, className)} src={imagePath} />
);
