import React from 'react';
import classNames from 'classnames';

import { ExperiencePlaceProps } from './exportedTypes';

import styles from './ExperienceItem.module.scss';

export const ExperiencePlace: React.FC<ExperiencePlaceProps> = ({ className, children, ...props }) => (
  <h2 className={classNames(styles.ExperiencePlace, className)} {...props}>
    {children}
  </h2>
);
