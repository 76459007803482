import React from 'react';
import classNames from 'classnames';

import styles from './MainContentContainer.module.scss';

export type MainContentContainerProps = React.HTMLProps<HTMLDivElement>;

export const MainContentContainer: React.FC<MainContentContainerProps> = ({ className, children }) => (
  <div className={classNames(styles.MainContentContainer, className)}>{children}</div>
);
