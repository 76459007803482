import React from 'react';
import classNames from 'classnames';

import styles from './Titles.module.scss';

const createTitleComponent = function <P extends { className?: string }>(
  Tag: React.ElementType,
  mainClassName: string,
): React.FC<P> {
  const TitleComponent: React.FC<P> = ({ children, className, ...props }) => (
    <Tag className={classNames(mainClassName, { [className || '']: !!className })} {...props}>
      {children}
    </Tag>
  );

  return TitleComponent;
};

export const TitleNameSurname = createTitleComponent('h1', styles.TitleNameSurname);

export const TitleWorkPlace = createTitleComponent('h3', styles.TitleWorkPlace);
