import React from 'react';
import classNames from 'classnames';

import { ExperienceTimeRangeProps } from './exportedTypes';

import styles from './ExperienceItem.module.scss';

export const ExperienceTimeRange: React.FC<ExperienceTimeRangeProps> = ({ className, from, to, ...props }) => (
  <div className={classNames(styles.TimeRange, className)} {...props}>
    {from} - {to}
  </div>
);
